import React, { useEffect, useState } from "react";
import { PDFViewer, Page, Text, View, Document, StyleSheet, Font, Image, pdf } from '@react-pdf/renderer';
import emailjs from 'emailjs-com';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore/lite';
import { initializeApp } from 'firebase/app';
import {convertToWords} from 'react-number-to-words';

import timesNewRomanFont from './times.ttf';
import timesNewRomanFontBold from './bold.ttf';
import backgroundImage from './sam-logo.png';

Font.register({ family: 'Times New Roman', src: timesNewRomanFont });
Font.register({ family: 'Bold', src: timesNewRomanFontBold });

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [mail, setMail] = useState("");
  const [basicPay, setBasicPay] = useState("");
  const [date, setDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [name, setName] = useState("");
  const [mrorms, setMrOrMs] = useState("");
  const [city, setCity] = useState("");
  const [addressline1, setAddressLine1] = useState("");
  const [addressline2, setAddressLine2] = useState("");
  const [state, setState] = useState("TAMILNADU");
  const [pincode, setPincode] = useState("");
  const [designation, setDesignation] = useState("");
  const [companyName, setCompanyName] = useState("SAM HYPER BUSINESS P.V.T L.T.D");

  const firebaseConfig = {
    apiKey: "AIzaSyCGMDhQP-EAnFmlR9r1hFeZB1S4BFpc3rQ",
    authDomain: "sam-hyper-business-682ea.firebaseapp.com",
    projectId: "sam-hyper-business-682ea",
    storageBucket: "sam-hyper-business-682ea.appspot.com",
    messagingSenderId: "707629335149",
    appId: "1:707629335149:web:1edbe7ab6c476cee02f564",
    measurementId: "G-WLYSXR186X"
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const storage = getStorage(app);

  const handleSubmit = (event) => {
    event.preventDefault();
    const validUsername = "SamHyperBusiness";
    const validPassword = "Sam@0987";

    if (username === validUsername && password === validPassword) {
      setAuthenticated(true);
      localStorage.setItem("authenticated", "true");
    } else {
      alert("Invalid username or password.");
    }
  };

  const handleLogout = () => {
    setAuthenticated(false);
    localStorage.removeItem("authenticated");
    setUsername("");
    setPassword("");
  };

  useEffect(() => {
    const isUserAuthenticated = localStorage.getItem("authenticated");
    if (isUserAuthenticated === "true") {
      setAuthenticated(true);
    }
  }, []);

  const formatDate = (inputDate) => {
    const dateParts = inputDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    return `${day}/${month}/${year}`;
  };

  const MyPDF = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>
        <View style={[styles.leftSpace, styles.rightText]}>
          <Text>Date: {formatDate(date)}</Text>
        </View>
        <View style={styles.bigSpace}></View>
        <View>
          <Text style={styles.redText}>{name},</Text>
        </View>
        <View style={styles.space}></View>
        <View>
          <Text style={styles.redText}>{addressline1},</Text>
        </View>
        <View style={styles.space}></View>
        <View>
          <Text style={styles.redText}>{addressline2},</Text>
        </View>
        <View style={styles.space}></View>
        <View>
          <Text style={styles.redText}>{state}, {city} {pincode}.</Text>
        </View>
        <View style={styles.bigSpace}></View>
        <View style={styles.space}></View>
        <View style={styles.centerText}>
          <Text><Text style={styles.boldtext}>Subject:</Text> Confirmation of Employment</Text>
        </View>
        <View style={styles.bigSpace}></View>
        <View style={styles.space}></View>
        <View>
          <Text>Dear <Text style={styles.redText}>{mrorms} {name}</Text>,</Text>
        </View>
        <View style={styles.space}></View>
        <View style={styles.space}></View>
        <View>
          <Text>As you have been working from {formatDate(startDate)} at {companyName}, we are happy to inform you that you have been confirmed to the position of {designation} at SAM HYPER BUSINESS P.V.T L.T.D with effect from {formatDate(date)}.</Text>
        </View>
        <View style={styles.space}></View>
        <View>
          <Text>Your salary has been revised to Rs.{basicPay}/- ({convertToWords(basicPay|| "")}) per month with effect from the confirmation date which indicates the cost to the company and other benefits.</Text>
        </View>
        <View style={styles.space}></View>
        <Text>We appreciate your contributions and commitment to the company. Your dedication and the quality of work you have produced have been impressive.</Text>
        <View style={styles.space}></View>
        <Text>As a permanent employee, you will continue to be bound by the company's terms and conditions. Your job role, responsibilities, and benefits will remain as per the terms stated in your initial employment contract unless otherwise notified.</Text>
        <View style={styles.space}></View>
        <Text>We look forward to your continued contributions and success at SAM HYPER BUSINESS P.V.T L.T.D. Congratulations on your confirmation.</Text>
        <View style={styles.space}></View>
        <View style={styles.space}></View>
        <View style={styles.space}></View>
        <View style={styles.space}></View>
        <Text>Best Regards,</Text>
        <View style={styles.space}></View>
        <View style={styles.space}></View>
        <View style={styles.space}></View>
        <View style={styles.space}></View>
        <Text>SAM HYPER BUSINESS PRIVATE LIMITED</Text>
      </Page>
    </Document>
  );

  const sendMail = async () => {
    try {
      const storageRef = ref(storage, `offerletter/${name}.pdf`);
      let blob = await pdf(MyPDF()).toBlob();
      await uploadBytes(storageRef, blob);
      const pdfRef = ref(storage, `offerletter/${name}.pdf`);
      const downloadURL = await getDownloadURL(pdfRef);
      const serviceID = 'service_1vakeah';
      const templateID = 'template_sz6369f';
      const userID = 'yuK_tXt2bSt5wElYE';
      const mailData = {
        name,
        mail,
        designation,
        downloadlink: downloadURL
      };
      await emailjs.send(serviceID, templateID, mailData, userID);
      alert("Email Sent Successfully");
    } catch (e) {
      alert("Error: please contact 9 840 841 840");
      console.log("Error: " + e);
    }
  };

  return (
    <div>
      {!authenticated ? (
        <div className="login-page-wrap">
          <div className="login-page-content">
            <div className="login-box">
              <form onSubmit={handleSubmit} className="login-form">
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    placeholder="Enter username"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <i className="far fa-envelope" />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Enter password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <i className="fas fa-lock" />
                </div>
                <div className="form-group">
                  <button type="submit" className="login-btn">Login</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="navbar navbar-expand-md header-menu-one bg-light">
            <div className="nav-bar-header-one">
              <div className="header-logo">
                <img src="img/dremerz-transparent-logo.png" width={"180px"} alt="logo" />
              </div>
              <div className="toggle-button sidebar-toggle">
                <button type="button" className="item-link">
                  <span className="btn-icon-wrap">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </button>
              </div>
            </div>
            <div className="d-md-none mobile-nav-bar">
              <button type="button" className="navbar-toggler sidebar-toggle-mobile">
                <i className="fas fa-bars"></i>
              </button>
            </div>
            <div className="header-main-menu collapse navbar-collapse" id="mobile-navbar">
              <ul id='navbar-nav'>
                <div id='sam-hyper-business'>Sam Hyper Business Private Limited</div>
              </ul>
              <ul className="navbar-nav">
                <li className="navbar-item dropdown header-admin">
                  <div className="navbar-nav-link dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <div className="admin-title">
                      <h5 className="item-title">S.H.B</h5>
                      <span
                        style={{
                          cursor: "pointer",
                          color: isHovered ? "red" : "black",
                        }}
                        onClick={handleLogout}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        Click to Log Out
                      </span>
                    </div>
                    <div className="admin-img">
                      <img src="img/figure/admin.jpg" alt="Admin" />
                    </div>
                  </div>
                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="item-header">
                      <h6 className="item-title">Steven Zone</h6>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div id="flex-div-pdf">
            <div id="div-one">
              <div className="form-group">
                <label>Employee Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Mr/Ms</label>
                <input
                  type="text"
                  className="form-control"
                  value={mrorms}
                  onChange={(e) => setMrOrMs(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Address Line 1</label>
                <input
                  type="text"
                  className="form-control"
                  value={addressline1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Address Line 2</label>
                <input
                  type="text"
                  className="form-control"
                  value={addressline2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>State</label>
                <input
                  type="text"
                  className="form-control"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Pincode</label>
                <input
                  type="text"
                  className="form-control"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Employee Designation</label>
                <input
                  type="text"
                  className="form-control"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Basic Pay</label>
                <input
                  type="text"
                  className="form-control"
                  value={basicPay}
                  onChange={(e) => setBasicPay(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Mail ID</label>
                <input
                  type="email"
                  className="form-control"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label>Click to Send Mail</label>
                <div
                  className="button"
                  onClick={sendMail}
                  style={{ cursor: "pointer" }}
                >
                  Click (:
                </div>
              </div>
            </div>
            <div>
              <PDFViewer id="div-two">
                <MyPDF />
              </PDFViewer>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const styles = StyleSheet.create({
  tablecontainer: {
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    borderBottom: 0.5,
    borderColor: 'grey',
  },
  flex: {
    flex: 1,
    textAlign: "right",
    paddingRight: 10
  },
  flex1: {
    flex: 1,
    borderRight: 0.5,
    paddingLeft: 10
  },
  testborder: {
    borderWidth: 2,
    borderColor: 'black',
  },
  centerText: {
    textAlign: "center"
  },
  rightText: {
    textAlign: "right"
  },
  leftText: {
    textAlign: "left"
  },
  page: {
    paddingTop: "27px",
    paddingBottom: "27px",
    paddingLeft: "50px",
    position: "relative",
    paddingRight: "50px",
    fontFamily: 'Times New Roman',
    fontSize: "12px",
  },
  watermarkContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.15,
  },
  watermark: {
    width: "80%"
  },
  logoContainer: {
    alignItems: "right",
    left: "270px",
    justifyContent: 'right',
  },
  logo: {
    width: "230px",
  },
  footerContainer: {
    display: "flex",
    left: 0,
    right: 0,
    borderColor: 'black',
    fontSize: "10px",
    position: "absolute",
    bottom: "37px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center"
  },
  boldtext: {
    fontWeight: 'bold',
    fontFamily: "Bold"
  },
  divider: {
    borderBottomWidth: 3,
    borderColor: '#8B0000',
    width: "80%",
  },
  dividerContainer: {
    display: "flex",
    left: 0,
    right: 0,
    fontSize: "10px",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    bottom: "25px",
    textAlign: "center",
  },
  space: {
    marginTop: "10px"
  },
  bigSpace: {
    marginTop: "20px"
  },
  leftSpace: {
    marginLeft: "15px"
  },
  redText: {
    color: "red",
  },
});

export default App;
